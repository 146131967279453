import React from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import Axios from 'axios';

import { encode } from  '../lib'

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal
    }, () => {
      this.refs.registerModal.style.display = this.state.modal ? 'block' : 'none';
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    const payload = {
      "form-name": "register",
      name: form.name.value,
      email: form.email.value,
      vendor_name: form.vendor_name.value,
      fb_page: form.fb_page.value,
      details: form.details.value,
    }

    Axios.post("/",
      encode(payload),
      { headers: { "Content-Type": "application/x-www-form-urlencoded" }},
    )
    .then(() => {
      alert("We have received your message. We will get back to you as soon as possible.\nThank you.")
      form.reset();
      this.refs.registerModal.style.display = 'none'
      this.setState({ modal: false })
    })
    .catch(() => {
      alert("Oops! Something went wrong.\nPlease try again later.")
    });
  };

  form() {
    return <Form
      name="register"
      method="post"
      action="/thanks"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
    >
      <ModalHeader toggle={this.toggle}>Register With Us</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="vendor_name">Service/Company Name*</Label>
          <Input type="text" name="vendor_name" id="vendor_name" required />
        </FormGroup>
        <FormGroup row>
          <Label sm={4} for="name">Your Name*</Label>
          <Col sm={8}>
            <input type="hidden" name="form-name" value="service_register" />
            <Input type="text" name="name" id="name" required />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4} for="email">Contact Email*</Label>
          <Col sm={8}>
            <Input type="email" name="email" id="email" required />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4} for="fb_page">Facebook Page</Label>
          <Col sm={8}>
            <Input type="text" name="fb_page" id="fb_page" />
          </Col>
        </FormGroup>
        <FormGroup>
          <Label for="details">Details*</Label>
          <Input type="textarea" name="details" id="details" rows="4" required />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" color="danger">Send</Button>
        {' '}
        <Button outline color="dark" onClick={this.toggle}>Cancel</Button>
      </ModalFooter>
    </Form>
  }

  render() {
    return (
      <div>
        <p class={this.props.alignBtn || 'text-center'}>
          <a href="" onClick={this.toggle} class="btn btn-danger">Register With Us</a>
        </p>
        <div class="modal" tabIndex="-1" role="dialog" ref="registerModal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              { this.form() }
            </div>
          </div>
        </div>
       { this.state.modal && <div class="modal-backdrop fade show"></div> }
      </div>
    );
  }
}

export default RegisterForm;
