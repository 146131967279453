import { Link } from 'gatsby'
import React from 'react'

import BoutiqueIcon from '../svgs/noun_Boutique.svg';
import EventPlannerIcon from '../svgs/noun_Event-Planner.svg';
import MakeupArtistIcon from '../svgs/noun_Makeup-Artist.svg';
import TarvelPlannerIcon from '../svgs/noun_Travel-Planner.svg';
import VideographerIcon from '../svgs/noun_Videographer.svg';
import PhotographerIcon from '../svgs/noun_Photographer.svg';
import FloristIcon from '../svgs/noun_Florist.svg';
import CakeIcon from '../svgs/noun_Cake.svg';
import VenueIcon from '../svgs/noun_Venue.svg';

const CategoryCards = () => {
  return <section class="main-block">
    <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-5">
      <div class="styled-heading">
        <h3>Browse Categories</h3>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/photographers" class="category-wrap">
          <div class="category-block">
          <PhotographerIcon />
          <h6>Photographers</h6>
          </div>
        </Link>
      </div>
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/videographers" class="category-wrap">
          <div class="category-block">
          <VideographerIcon />
          <h6>Videographers</h6>
          </div>
        </Link>
      </div>
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/hair-and-beauty" class="category-wrap">
          <div class="category-block">
          <MakeupArtistIcon />
          <h6>Hair N Beauty</h6>
          </div>
        </Link>
      </div>
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/boutiques" class="category-wrap">
          <div class="category-block">
          <BoutiqueIcon />
          <h6>Boutiques</h6>
          </div>
        </Link>
      </div>

      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/florists" class="category-wrap">
          <div class="category-block">
          <FloristIcon />
          <h6>Florists</h6>
          </div>
        </Link>
      </div>
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/cakes" class="category-wrap">
          <div class="category-block">
          <CakeIcon />
          <h6>Cakes</h6>
          </div>
        </Link>
      </div>
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/venues" class="category-wrap">
          <div class="category-block">
          <VenueIcon />
          <h6>Venues</h6>
          </div>
        </Link>
      </div>
      {/* <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/event-planners" class="category-wrap">
          <div class="category-block">
          <EventPlannerIcon />
          <h6>Event Planners</h6>
          </div>
        </Link>
      </div> */}
      <div class="col-6 col-sm-3 col-md-3 col-lg-3 category-responsive">
        <Link to="/services/travel-experts" class="category-wrap">
          <div class="category-block">
          <TarvelPlannerIcon />
          <h6>Travel Planners</h6>
          </div>
        </Link>
      </div>
    </div>
    </div>
  </section>
}

export default CategoryCards;
