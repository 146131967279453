import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'

import Layout from '../layouts/home'
import SEO from '../components/seo'
import RegisterForm from '../components/registerForm'
import Featured from '../components/featuredCard'
import CategoryCards from '../components/categoryCards'

export default function IndexPage({ data }) {

  const { categories, markets } = data.site.siteMetadata;

  const selectForMarkets = () => {
    const marketOptions = markets.map(m => {
      return <option value={m}>
        { m.titleize() }
      </option>
    })
    return <select class="custom-select" onChange={setMarket}>
      { marketOptions }
    </select>
  }

  const selectForCategories = () => {
    const categoryOptions = categories.map(c => {
      return <option value={c}>
        { c.titleize() }
      </option>
    })
    return <select class="custom-select" onChange={setCategory}>
      { categoryOptions }
    </select>
  }

  const setCategory = (e) => {
    state.selectedCategory = e.target.value
  }
  const setMarket = (e) => {
    state.selectedMarket = e.target.value
  }

  let state = {
    selectedCategory: "photographers",
    selectedMarket: "kathmandu"
  }

  const search = () => {
    window.location = `/services/${state.selectedCategory}/${state.selectedMarket}`
  }

  const listFeatured = () => {
    return data.allMarkdownRemark.edges.map(e => <Featured frontmatter={e.node.frontmatter} key={e.node.frontmatter.path} />)
  }

  return <Layout>
    <SEO
      title="Vendors and venues for weddings and events in Nepal"
      keywords={[
        'weddings', 'events', 'vendors', 'venues', 'photographers', 'videographers', 'makeup and hair', 'boutiques', 'florists', 'cakes', 'planners', 'travel and tours', "kathmandu", "nepal"
      ]}
      meta={[
        {
          property: 'og:image',
          content: `http://www.ceremonynepal.com/images/landing.jpg`
        }
      ]}
    />
    <section class="slider d-flex align-items-center">
      <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-12">
        <div class="slider-title_box">
          <div class="row">
          <div class="col-md-12">
            <div class="slider-content_wrap">
            <h4>Let's find a team for your events and celebrations</h4>
            <p>Discover the best photographers, videographers, boutiques, make-up artists, event planners and more</p>
            </div>
          </div>
          </div>
          <div class="row d-flex justify-content-center">
          <div class="col-md-6">
            <form class="form-wrap mt-4">
              <div class="input-group input-group-md">
                { selectForCategories() }
                { selectForMarkets() }
                <div class="input-group-append">
                <button class="btn btn-danger" type="button" onClick={search}>SEARCH</button>
                </div>
              </div>
            </form>
            <div class="slider-link">
            <a href="#PopularCategories">Browse Popular Searches</a><span>or</span> <a href="#Spotlight">Vendors on Spotlight</a>
            </div>
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </section>
    <CategoryCards />
    <section class="main-block light-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5">
          <div class="styled-heading" id="Spotlight">
            <h3>In The Spotlight</h3>
          </div>
          </div>
        </div>
        <div class="row">
          { listFeatured() }
        </div>
      </div>
    </section>
    <section class="main-block">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="add-listing-wrap">
              <h2>Are you a Service Provider?</h2>
              <p>
                Contact us to include your service in our listing.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="featured-btn-wrap">
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
}

export const query = graphql`
  query GetAll {
    site {
      siteMetadata {
        markets
        categories
      }
    }

    allMarkdownRemark(limit: 4, filter: { frontmatter: { spotlight: { eq: true } }}) {
      edges {
        node {
          frontmatter {
            path
            title
            location
            featured
            rating
            categories
            badges {
              liked
              reviewed
              price
              reply
            }
            profileImage {
              childImageSharp {
                fixed(width: 420, height: 340) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
