import { Link } from 'gatsby'
import React from 'react'

import ResponsiveIcon from '../svgs/responsive.svg';
import LikedIcon from '../svgs/liked.svg';
import PriceIcon from '../svgs/price.svg';
import ReviewedIcon from '../svgs/reviewed.svg';

const FeaturedCard = ({ frontmatter }) => {

  const { profileImage, badges, title, location, path, rating, categories } = frontmatter;

  const cats = categories.join(" & ").titleize()

  return <div class="col-sm-6 col-md-6 col-lg-3 featured-responsive">
    <div class='featured-place-wrap'>
      <Link to={`/services/${path}`}>
        <img src={profileImage.childImageSharp.fixed.src} class="img-fluid" />
        <div class="featured-title-box">
          { rating && <div className="featured-rating">{ rating }</div> }
          <h6>{title}</h6>
          <h6 class="title-cat" title={`In ${cats}`}>In { cats }</h6>
          <p>{ location }</p>
          <div class="row bee-badges-row">
            {
              badges.price && <div class="col-6 col-xs-6 col-md-6 col-lg-3">
                <div class="bee-btn">
                  <i class="icon"><PriceIcon /></i>
                  <div>BEST PRICE</div>
                </div>
              </div>
            }
            {
              badges.liked && <div class="col-6 col-xs-6 col-md-6 col-lg-3">
                <div class="bee-btn">
                  <i class="icon"><LikedIcon /></i>
                  <div>WELL LIKED</div>
                </div>
              </div>
            }
            {
              badges.reviewed && <div class="col-6 col-xs-6 col-md-6 col-lg-3">
                <div class="bee-btn">
                  <i class="icon"><ReviewedIcon /></i>
                  <div>TOP REVIEWS</div>
                </div>
              </div>
            }
            {
              badges.reply && <div class="col-6 col-xs-6 col-md-6 col-lg-3">
                <div class="bee-btn">
                  <i class="icon"><ResponsiveIcon /></i>
                  <div>FAST REPLIES</div>
                </div>
              </div>
            }
          </div>
        </div>
      </Link>
    </div>
  </div>
}

export default FeaturedCard
